import moment from 'moment';
import api from '../api/vehiclesHistoryApi';
import { OperationResult } from '../types/apiTypes';
import { BusHistoryArrival, BusPositionByBusNumber, BusPositionTrip, PredictionOnStop, RtVehiclePosition, StopPredictionDto, TripStopData, VehiclePositionState } from '../types/busHistoryTypes';
import { GtfsStop2 } from '../types/gtfsTypes';
import { KeyValuePair } from '../types/types';
import { VehicleStatisticsData } from '../types/vehicleStatisticsTypes';

export async function getVehiclesStatistics(agencyId: string, fromDate: string, toDate: string): Promise<VehicleStatisticsData[]> {
    const result = await api.getVehiclesStatistics(agencyId, fromDate, toDate);
    return result.data || [];
}
export async function getVehicleStopArrivals(agencyId: string, vehicleId: string, serviceDate: string) {
    const result = await api.getVehicleStopArrivals(agencyId, vehicleId, serviceDate);
    return result.data || [];
}
export async function getVehicles(
    agencyId: string,
    date: string,
): Promise<KeyValuePair<string, string>[]> {
    const result = await api.getVehicles(agencyId, date);
    return result.data || [];
}

export async function getTripsData(
    agencyId: string,
    date: string,
    vehicleId: string,
): Promise<BusPositionTrip[]> {
    const result = await api.getTripsData(agencyId, date, vehicleId);
    return result.data || [];
}

export async function getVehiclePositions(
    agencyId: string,
    date: string,
    tripId: string,
    vehicleId: string,
): Promise<BusPositionByBusNumber[]> {
    const result = await api.getVehiclePositions(agencyId, date, tripId, vehicleId);
    return result.data || [];
}

export async function getRtVehiclePositionData(
    agencyId: string,
    tripId: string,
    vehicleId: string,
): Promise<RtVehiclePosition[]> {
    const result = await api.getRtVehiclePositionData(agencyId, tripId, vehicleId);
    return result.data || [];
}

export async function getTripStopsData(
    agencyId: string,
    tripId: string,
    reportDate: Nullable<string> = null,
): Promise<TripStopData[]> {
    const result = await api.getTripStopsData(agencyId, tripId, reportDate);
    return result.data || [];
}

export async function getBusHistoryArrivals(
    agencyId: string,
    date: string,
    tripId: string,
    vehicleId: string,
): Promise<BusHistoryArrival[]> {
    const result = await api.getBusHistoryArrivals(agencyId, date, tripId, vehicleId);
    return result.data || [];
}

export async function getStops(agencyId: string, filter: string): Promise<GtfsStop2[]> {
    const result = await api.getStops(agencyId, filter);
    return result.data || [];
}

export async function getSchedule(serviceDate: string, stopId: string, directionVariantId: string): Promise<Date[]> {
    const result = await api.getSchedule(serviceDate, stopId, directionVariantId);
    return result.data || [];
}

export async function getPositions(agencyId: string, directionVariantId: string, serviceDateTime: string): Promise<VehiclePositionState[] | null> {
    const apires = await api.getPositions(agencyId, directionVariantId, serviceDateTime);
    if (!apires.isSuccess)
        return null;
    const busPositions = apires.data;
    busPositions.sort((a, b) => {
        let res = a.vehicleTime.localeCompare(b.vehicleTime);
        if (res === 0)
            res = (a.traveledKm ?? -1) - (b.traveledKm ?? - 1);
        return res;
    });
    const vehiclePositions = busPositions.map(e => {
        const res: VehiclePositionState = {
            tripId: e.tripId,
            vehicleId: e.vehicleId,
            latitude: e.latitude,
            longitude: e.longitude,
            reportTimeAtz: e.vehicleTime,
            blockId: e.blockId,
        };
        if (typeof e.delaySec == 'number')
            res.delaySec = e.delaySec;
        if (typeof e.speedKmH == 'number')
            res.speed = e.speedKmH;

        if (typeof e.headwaySec == 'number' && !!e.nextVehicleId) {
            res.next = {
                seconds: e.headwaySec,
                vehicleId: e.nextVehicleId,
                tripId: e.nextTripId || '',
            };
        }
        if (typeof e.behindSec == 'number' && !!e.prevVehicleId) {
            res.prev = {
                seconds: e.behindSec,
                vehicleId: e.prevVehicleId,
                tripId: e.prevTripId || '',
            };
        }
        return res;
    });
    return vehiclePositions;
}

export async function getStopPredictions(agencyId: string, stopId: string, directionVariantId: string, serviceDateTime: string): Promise<OperationResult<StopPredictionDto[]>> {
    const result = await api.getStopPredictions(agencyId, stopId, directionVariantId, serviceDateTime);
    if (result && result.isSuccess) {
        result.data.sort((a, b) => {
            let r = a.predictedTime.localeCompare(b.predictedTime);
            if (r === 0)
                r = a.scheduledTime.localeCompare(b.scheduledTime);
            if (r === 0)
                r = a.actualTime.localeCompare(b.actualTime);
            return r;
        });
    }
    return result;
}

export async function getPredictionsByPosition(agencyId: string, serviceDate: string, tripId: string, vehicleTime: Date, lat: Latitude, lon: Longitude): Promise<PredictionOnStop[]> {
    const vehicleTimeStr = moment.utc(vehicleTime).format('YYYY-MM-DDTHH:mm:ss');
    const result = await api.getPredictionsByPosition(agencyId, serviceDate, tripId, vehicleTimeStr, lat, lon);
    return result || [];
}
